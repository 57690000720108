import React from 'react';
import {StatusesJob, Unit} from '@features/Jobs/jobs.types';
import Permission from '@components/Permission';
import {BUTTON_THEMES} from 'ht-styleguide';
import {useAppDispatch} from '@store/store';
import {jobsDuck} from '@features/Jobs/Jobs.ducks';

import FloatingActionButton from '@components/UI/FloatingButton';
import {useRouter} from 'next/router';
import {projectListPath} from '@constants/constants.paths';
import {FULFILLED} from '@constants/constants.base';

type StatusApprovalButtonProps = {
  unit: Unit;
};

/*
type ButtonBehaviour = {
  [key in keyof typeof StatusesJob]: {
    cta: string;
    action: Function;
    theme: typeof BUTTON_THEMES;
    permission?: any
  }
} */

const StatusApprovalButton = ({unit}: StatusApprovalButtonProps) => {
  const dispatch = useAppDispatch();
  /* Hooks */
  const {
    push,
    query: {pid},
  } = useRouter();
  /* Constants */
  const statusObj = {
    [StatusesJob.cancelled]: {cta: 'Reopen', action: jobsDuck.actions.reopenJob, theme: BUTTON_THEMES.SECONDARY},
    [StatusesJob.unserviceable]: {cta: 'Reopen', action: jobsDuck.actions.reopenJob, theme: BUTTON_THEMES.SECONDARY},
    [StatusesJob.completed]: {cta: 'Reopen', action: jobsDuck.actions.reopenJob, theme: BUTTON_THEMES.SECONDARY},
    [StatusesJob.pending_approval]: {cta: 'Approve', action: jobsDuck.actions.approveJob, postAction: () => push(projectListPath(pid)), theme: BUTTON_THEMES.PRIMARY, permission: true},
  };
  const statusRef = statusObj[unit.status] || null;

  /* Methods */
  const onHandleSubmit = async () => {
    const {
      meta: {requestStatus},
    } = await dispatch(statusRef.action());

    if (statusRef.postAction && requestStatus === FULFILLED) {
      statusRef.postAction();
    }
  };

  const DisplayWrapper = ({children}: {children: JSX.Element}) => {
    if (statusRef.permission) {
      return <Permission>{children}</Permission>;
    }

    return children;
  };

  if (!statusRef) return null;

  return (
    <DisplayWrapper>
      <div className="button_bottom">
        <FloatingActionButton theme={statusRef.theme} buttonAction={onHandleSubmit} suppress={false} title={statusRef.cta} />
        <style jsx>{`
          .button_bottom {
            bottom: 30px;
            left: calc(50% - 100px);
            width: 200px;
            position: absolute;
          }
        `}</style>
      </div>
    </DisplayWrapper>
  );
};

export default StatusApprovalButton;
