import {CancelReasonType, UnitSearchFilter, StatusesJob} from '@features/Jobs/jobs.types';

export const formatUnitStatus = (status: string) =>
  // Take the snake_case status from BE and transform to human readable format
  status.replace('_', ' ').toUpperCase();

/* Takes Cancelled Reasons and formats for dropdowns */
export const normalizeCancelReasonForSelect = (reasons: CancelReasonType[] = []) => reasons?.map(reason => ({value: reason.id, label: reason.text}));

/* Alpha sort, and secondary on status (note: completed_at is always null? BE already returns in order of adding) */
const StatusSort = [StatusesJob.completed, StatusesJob.flagged, StatusesJob.pending_approval, StatusesJob.unserviceable];
export const sortServices = ([...services]) => {
  if (!Array.isArray(services)) return [];

  return services.sort((a, b) => a.sku.name.localeCompare(b.sku.name) || StatusSort.indexOf(b.status) + 1 - (StatusSort.indexOf(a.status) + 1));
};

/* Need to know if status filtering has any length. Helper used standalone and in ducks */
/* eslint-disable-next-line no-return-assign */
export const statusFilterLength = (filters: UnitSearchFilter) => filters && Object.values(filters).reduce((sum, status) => (sum += status.length), 0);
