import React from 'react';
import {ProjectServices} from '@features/Projects/projects.types';
import cn from 'classnames';

// eslint-disable-next-line import/prefer-default-export
export const GroupSelectionInfo = ({services = [], title, selected, allowHover = false}: {services: ProjectServices[]; title?: string; selected?: boolean; allowHover?: boolean}) => {
  if (!services) return null;

  const GroupTitle = () => (title ? <div className="h6 text-weight-medium paddingBottom-tiny">{title}</div> : null);

  return (
    <div className="container flex flexDirectionColumn marginTop-small">
      <GroupTitle />
      {services?.map(service => {
        const qtyDisplay = service.quantity ? `${service.quantity}x` : '';
        const spaceForQty = cn('p2 n800', {quantity: qtyDisplay});
        const nameClass = cn('p2 n800', {templateName: qtyDisplay, name: !qtyDisplay});

        return (
          <div className="flex flexDirectionRow paddingY-tiny n700" key={service.id} data-testid="group_template">
            <div className={spaceForQty}>{qtyDisplay}</div>
            <div className={nameClass}>{service.sku.name}</div>
          </div>
        );
      })}
      <style jsx>{`
        .container {
          background-color: ${selected ? '#f2fbfb' : '#fbfdfe'};
          border: 1px solid ${selected ? '#00c9bc' : '#ccd4dd'};
          border-radius: 5px;
          width: 100%;
          padding: 16px;
        }
        .container:hover {
          ${allowHover &&
          `
               cursor: pointer;
               background-color: #f2fbfb;
               border: 1px solid #00c9bc;
             `}
        }
        .quantity {
          padding-right: 8px;
        }
        .templateName {
          flex: 1;
        }
        .name {
          width: 100%;
        }
      `}</style>
    </div>
  );
};
